@font-face {
  font-family: "Be Vietnam Pro";
  src: url("../../fonts/BeVietnamPro-ExtraBold.woff2") format("woff2"),
    url("../../fonts/BeVietnamPro-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Be Vietnam Pro";
  src: url("../../fonts/BeVietnamPro-BoldItalic.woff2") format("woff2"),
    url("../../fonts/BeVietnamPro-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Be Vietnam Pro";
  src: url("../../fonts/BeVietnamPro-Bold.woff2") format("woff2"),
    url("../../fonts/BeVietnamPro-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Be Vietnam Pro";
  src: url("../../fonts/BeVietnamPro-Black.woff2") format("woff2"),
    url("../../fonts/BeVietnamPro-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Be Vietnam Pro";
  src: url("../../fonts/BeVietnamPro-ExtraBoldItalic.woff2") format("woff2"),
    url("../../fonts/BeVietnamPro-ExtraBoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Be Vietnam Pro";
  src: url("../../fonts/BeVietnamPro-BlackItalic.woff2") format("woff2"),
    url("../../fonts/BeVietnamPro-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Be Vietnam Pro";
  src: url("../../fonts/BeVietnamPro-Medium.woff2") format("woff2"),
    url("../../fonts/BeVietnamPro-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Be Vietnam Pro";
  src: url("../../fonts/BeVietnamPro-ExtraLight.woff2") format("woff2"),
    url("../../fonts/BeVietnamPro-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Be Vietnam Pro";
  src: url("../../fonts/BeVietnamPro-ExtraLightItalic.woff2") format("woff2"),
    url("../../fonts/BeVietnamPro-ExtraLightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Be Vietnam Pro";
  src: url("../../fonts/BeVietnamPro-LightItalic.woff2") format("woff2"),
    url("../../fonts/BeVietnamPro-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Be Vietnam Pro";
  src: url("../../fonts/BeVietnamPro-Italic.woff2") format("woff2"),
    url("../../fonts/BeVietnamPro-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Be Vietnam Pro";
  src: url("../../fonts/BeVietnamPro-Light.woff2") format("woff2"),
    url("../../fonts/BeVietnamPro-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Be Vietnam Pro";
  src: url("../../fonts/BeVietnamPro-Regular.woff2") format("woff2"),
    url("../../fonts/BeVietnamPro-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Be Vietnam Pro";
  src: url("../../fonts/BeVietnamPro-SemiBoldItalic.woff2") format("woff2"),
    url("../../fonts/BeVietnamPro-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Be Vietnam Pro";
  src: url("../../fonts/BeVietnamPro-SemiBold.woff2") format("woff2"),
    url("../../fonts/BeVietnamPro-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Be Vietnam Pro";
  src: url("../../fonts/BeVietnamPro-Thin.woff2") format("woff2"),
    url("../../fonts/BeVietnamPro-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Be Vietnam Pro";
  src: url("../../fonts/BeVietnamPro-MediumItalic.woff2") format("woff2"),
    url("../../fonts/BeVietnamPro-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../../fonts/Roboto-Medium.woff2") format("woff2"),
    url("../../fonts/Roboto-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Be Vietnam Pro";
  src: url("../../fonts/BeVietnamPro-ThinItalic.woff2") format("woff2"),
    url("../../fonts/BeVietnamPro-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

.font-title {
  font-family: "Be Vietnam Pro";
}

h1 {
  font-size: 2.25rem;
  letter-spacing: -2px;
  line-height: 1.1;
  font-weight: bold;
  @extend .font-title;
  @include media-breakpoint-up(md) {
    font-size: 5rem;
    letter-spacing: -5px;
    line-height: 1.1;
    font-weight: bold;
  }
}

h2 {
  font-size: 1.875rem;
  letter-spacing: -2px;
  line-height: 1.1;
  font-weight: bold;
  @extend .font-title;
  @include media-breakpoint-up(md) {
    font-size: 4rem;
    letter-spacing: -4px;
  }
}