.screen-1 {
  .main-photo {
    @include media-breakpoint-up(md) {
      height: calc(100vh - 114px);
    }
    img {
      width: 700px;
      max-width: 100%;
    }
  }
  .intro {
    p {
      font-size: 0.75rem;
      @include media-breakpoint-up(md) {
        font-size: 1.5rem;
      }
    }
  }
  .intro-text {
    margin-top: -100px;
    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
  }
  .button-scroll-down {
    bottom: 40px;
    left: 50%;
    cursor: pointer;
    margin-left: -20px;
    svg {
      width: 54px;
      height: 54px;
      transition: all 0.2s;
    }
    &:hover {
      svg {
        transform: scale(1.2);
      }
    }
  }
}

.screen-2 {
  .container {
    height: 100%;
    background: url(../../images/about-us-mobile.png) no-repeat;
    background-size: contain;
    background-position: top 0 left 0;
    padding-top: 300px;
    @include media-breakpoint-up(md) {
      padding-top: 0;
      background: url(../../images/about-us-desktop.png) no-repeat 100%;
      background-size: 80%;
      background-position: top 0 right 0;
    }
    h2 {
      max-width: 960px;
    }
    p {
      max-width: 640px;
      @include media-breakpoint-down(md) {
        font-size: 0.75rem;
      }
    }
  }
}

.screen-3 {
  @include media-breakpoint-down(md) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  @include media-breakpoint-up(md) {
    .container {
      min-height: 50vh;
    }
  }
}

.process-marker {
  display: block;
  width: 7rem;
  height: 7rem;
  text-align: center;
  font-size: 4rem;
  line-height: calc(7rem - 8px);
  border: 3px solid $black;
  border-radius: 50%;
  margin-right: 5rem;
  @include media-breakpoint-down(lg) {
    margin-right: 2rem;
  }
  @include media-breakpoint-down(md) {
    width: 3.125rem;
    height: 3.125rem;
    font-size: 1.875rem;
    line-height: calc(3.125rem - 8px);
    margin-right: 1.25rem;
    background-color: $gray-300;
    position: relative;
    .process-connector {
      width: 10px;
      height: 400%;
      background-color: $black;
      display: block;
      position: absolute;
      left: 50%;
      margin-left: -5px;
      z-index: -2;
    }
    .process-progress {
      width: 10px;
      background-color: $primary;
      display: block;
      position: absolute;
      left: 50%;
      margin-left: -5px;
      z-index: -1;
    }
  }
}

.title-topline {
  font-size: 1.5rem;
  @include media-breakpoint-down(md) {
    font-size: 0.75rem;
  }
  &:before {
    width: 160px;
    height: 3px;
    content: "";
    background-color: $black;
    margin-bottom: 10px;
    display: block;
    @include media-breakpoint-down(md) {
      height: 1px;
      margin-bottom: 4px;
      width: 80px;
    }
  }
}

.section-process {
  .process-content {
    padding: 4rem 0;
    @include media-breakpoint-down(md) {
      padding-top: 0 !important;
      padding-bottom: 2rem !important;
    }
    @include media-breakpoint-up(md) {
      min-height: 50vh;
      display: flex;
      align-items: center;
      .font-title {
        padding-top: 20px;
      }
    }
    .title-topline {
      text-align: left;
      display: none;
      &:before {
        background-color: #fff;
      }
    }
    &.active {
      @include media-breakpoint-down(md) {
        .process-marker {
          background-color: $primary;
          border-color: $primary;
          color: #fff;
        }
      }
    }
  }
  p {
    font-size: 1.5rem;
    max-width: 550px;
    @include media-breakpoint-down(md) {
      font-size: 0.75rem;
    }
  }
  &.active {
    @include media-breakpoint-up(md) {
      .process-content {
        background-color: $primary;
        color: #fff;
        .title-topline {
          display: block;
          color: #fff;
        }
        .process-marker {
          border-color: #fff;
        }
      }
    }
  }
  &.fp-auto-height {
    padding-top: 0 !important;
  }
}

.screen-4 {
  background: url(../../images/service_desktop_1.jpg) no-repeat center/cover;
}
.screen-5 {
  background: url(../../images/service_desktop_2.jpg) no-repeat center/cover;
}
.screen-6 {
  background: url(../../images/service_desktop_3.jpg) no-repeat center/cover;
}

.carousel-wrapper {
  .carousel-indicators {
    position: relative;
    justify-content: start;
    @include media-breakpoint-up(md) {
      margin-left: 10%;
    }
    [data-bs-target] {
      width: 16px;
      height: 16px;
      border: 1px solid #707070;
      background: $gray-300;
      border-radius: 50%;
      opacity: 1;
      &.active {
        background: #9f9f9f;
      }
    }
  }
  .carousel-header {
    padding-left: 10%;
  }
  .carousel-footer {
    @include media-breakpoint-up(md) {
      padding-right: 10%;
      padding-bottom: 0;
    }
    margin-top: 30px;
    padding-bottom: 50px;
  }
  .carousel-inner {
    h2 {
      &:after {
        margin-top: 32px;
        margin-bottom: 60px;
        content: "";
        display: block;
        width: 100%;
        height: 3px;
        background: $black;
      }
    }
    .slide-content {
      font-size: 0.75rem;
      @include media-breakpoint-up(md) {
        font-size: 1.25rem;
      }
    }
  }
  @media (max-height: 750px) {
    .carousel-inner {
      h2 {
        font-size: 2.25rem;
        letter-spacing: -2px;
      }
      .slide-content {
        font-size: 1rem;
      }
    }
  }
  @media (max-width: 768px) {
    .mobile-arrow {
      width: 5% !important;
    }
  }
}

.carousel {
  .carousel-control-prev,
  .carousel-control-next {
    width: 10%;
  }
  .carousel-item {
    padding-left: 10%;
    padding-right: 10%;
  }
  .carousel-control-next-icon {
    background-image: url(../../icons/arrow_right.svg);
    filter: none;
  }
  .carousel-control-prev-icon {
    background-image: url(../../icons/arrow_left.svg);
    filter: none;
  }
}

.section-technologies {
  p {
    font-size: 0.75rem;
    max-width: 170px;
    @include media-breakpoint-up(md) {
      font-size: 1.5rem;
      max-width: 640px;
    }
  }
  @include media-breakpoint-up(md) {
    padding-bottom: 50px;
  }
}

.section-contact {
  background-color: $primary;
  .contact-info {
    color: #fff;
    font-size: 1.5rem;
    border-top: 2px solid #fff;
    @include media-breakpoint-down(md) {
      font-size: 0.756rem;
    }
    a {
      color: #fff;
      text-decoration: none;
    }
    .contact-address {
      @include media-breakpoint-down(md) {
        font-size: 0.75rem;
      }
    }
    .contact-phone,
    .contact-mail,
    .contact-address {
      display: flex;
      align-items: center;
      p {
        margin-bottom: 0;
      }
    }
    svg {
      max-height: 30px;
      max-width: 30px;
      margin-right: 20px;
      @include media-breakpoint-down(md) {
        max-height: 20px;
        max-width: 20px;
      }
    }
  }
  .contact-wrapper {
    position: relative;
    z-index: 9;
  }
  .icon-footer-1,
  .icon-footer-2 {
    display: inline-block;
    width: 140px;
    height: 68px;
    background: url(../../icons/contactbg2.svg) no-repeat center/cover;
    position: absolute;
  }
  .icon-footer-3,
  .icon-footer-4 {
    display: inline-block;
    width: 96px;
    height: 96px;
    background: url(../../icons/contactbg1.svg) no-repeat center/cover;
    position: absolute;
  }
  .icon-footer-1 {
    top: 25%;
    left: -70px;
  }
  .icon-footer-2 {
    top: 40%;
    left: 40%;
  }
  .icon-footer-3 {
    top: 5%;
    left: 50%;
  }
  .icon-footer-4 {
    top: 70%;
    right: 20px;
  }
  @include media-breakpoint-down(md) {
    .icon-footer-1,
    .icon-footer-2,
    .icon-footer-4 {
      display: none;
    }
    .icon-footer-3 {
      top: 0;
      right: 0;
      left: auto;
    }
  }
  @media (max-height: 750px) {
    .form-control {
      padding: 0.5rem 1.5rem;
    }
    textarea.form-control {
      min-height: 5rem;
    }
    .container {
      .footer-form {
        padding-top: 0 !important;
      }
    }
    .contact-info {
      font-size: 1rem;
    }
  }
  @include media-breakpoint-down(xl) {
    .footer-form {
      h1 {
        font-size: 3.8rem;
      }
    }
    .contact-info {
      font-size: 1.2rem;
    }
  }
  .errMsg {
    color: red;
    margin-top: 10px;
    position: absolute;
  }
  .error {
    border: 2px solid red;
  }
  .successMsg {
    color: white;
    margin-top: 10px;
    position: absolute;
  }
}

.footer {
  background-color: $gray-300;
  @include media-breakpoint-down(md) {
    font-size: 0.75rem;
  }
}

.fullpage-wrapper {
  width: 100vw;
}

@media (min-width: 768px) {
  .screen-1 > div,
  .screen-2 > div {
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
  }
}
