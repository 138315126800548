.logo {
  width: 68px;
  @include media-breakpoint-up(md) {
    width: 100px;
  }
  img {
    width: 100%;
  }
}

.navbar-top {
  .nav-link {
    font-size: 1rem;
    padding: 0.5rem 1.5rem;
    &.active {
      color: $purple;
    }
  }
}

header {
  z-index: 99;
  &.scrolled {
    background-color: $gray-300;
    @include media-breakpoint-up(md) {
      .logo {
        width: 60px;
      }
      -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
    }
  }
}

.hamburger {
  border: none;
  width: 1.5rem;
  height: 1.5rem;
  background: url(../../icons/hamburger.svg) no-repeat center/contain;
}

.mobile-menu {
  background: $primary;
  color: #fff;
  ul {
    list-style: none;
    padding-left: 1rem;
    @extend .font-title;
    li {
      a {
        color: #fff;
        font-size: 2rem;
        &.active {
          color: $secondary;
        }
      }
    }
  }
  .btn-close {
    width: 1.5rem;
    height: 1.5rem;
    background: url(../../icons/X.svg) no-repeat center/cover;
    filter: brightness(1);
    opacity: 1;
    padding: 0;
    margin: 0;
  }
  .offcanvas-header {
    justify-content: end;
    padding: 3rem 2rem;
  }
}
