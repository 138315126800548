.section-technologies {
  padding-top: 120px;
  background: url(../../images/technologies_bg.png) no-repeat;
  background-color: $gray-300;
  background-size: 720px;
  background-position: top 120px right 0;
  h1 {
    margin-bottom: 30px;
    @include media-breakpoint-down(md) {
      margin-bottom: 15px;
    }
  }
  p {
    font-size: 1.5rem;
    max-width: 384px;
    @include media-breakpoint-down(md) {
      font-size: 0.75rem;
      max-width: 220px;
    }
  }
  .container {
    padding-bottom: 160px;
  }
  @include media-breakpoint-down(md) {
    background-size: 360px;
    background-position: top 40% right 0;
  }
}

.section-technologies-list-wrapper {
  .section-technologies-list:nth-of-type(odd) {
    background-color: $gray-400;
  }
}

.section-technologies-list {
  padding: 70px 0;
  @include media-breakpoint-down(md) {
    padding: 50px 0;
  }
  h3 {
    @extend .font-title;
    font-size: 1.5rem;
    margin-bottom: 40px;
    word-break: break-word;
    max-width: 200px;
    line-height: 2.4rem;
    @include media-breakpoint-down(md) {
      max-width: 100%;
    }
  }
  .tech-list {
    .tech-item-wrapper {
      float: left;
      margin-right: 30px;
      margin-bottom: 20px;
      @include media-breakpoint-down(md) {
        margin-right: 9px;
        width: 68px;
      }
      img {
        width: 150px;
        height: 150px;
        object-fit: contain;
        @include media-breakpoint-down(md) {
          width: 68px;
          height: 68px;
        }
      }
      span {
        display: block;
        font-size: 1rem;
        text-align: center;
        margin-top: 20px;
        max-width: 100%;
        word-break: break-word;
        @include media-breakpoint-down(md) {
          font-size: 0.625rem;
          margin-top: 10px;
        }
      }
    }
  }
}
