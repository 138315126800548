$purple: #6d47cf;
$primary: $purple;
$secondary: #DD95FB;
$gray-400: #CBBDBC;
$gray-300: #F7F2EE;
$black: #090909;
$nav-link-color: $black;

@import "../../../node_modules/bootstrap/scss/bootstrap.scss";
@import "./configs/typo";
@import "./components/header";
@import "./components/home";
@import "./components/technologies";

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $gray-300;
  color: $black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app {
  @include media-breakpoint-down(md) {
    .container {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
}

.btn-xl {
  box-shadow: 8px 8px 5px rgba(0, 0, 0, 16%);
  padding: 0.875rem 2rem;
  font-size: 0.75rem;
  &.shadow-none {
    box-shadow: none;
  }
  @include media-breakpoint-up(md) {
    font-size: 1.125rem;
  }
}

.transition {
  transition: all .2s ease-in-out;
}

.form-control {
  padding: 1rem 2rem;
  border-radius: 0;
  color: $gray-400!important;
  @include media-breakpoint-down(md) {
    font-size: 0.75rem;
    padding: 0.75rem 1rem;
  }
}

.form-control::-webkit-input-placeholder {
  color: $gray-400!important;
}
.form-control:-ms-input-placeholder {
  color: $gray-400!important;
}
.form-control::placeholder {
  color: $gray-400!important;
}

textarea.form-control {
  min-height: 8rem;
}